@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.container {
    max-width: 77.7rem;
    margin-top: 2.2rem;

    @screen md {
        margin-top: 4rem;
    }
}

.card {
    padding: 2.4rem 1.6rem;

    @screen md {
        padding: 3.2rem;
    }
}

.cardSubtitle {
    font-weight: 500;
    font-size: $text-base;
    line-height: 1.8rem;
    color: $grey;
    margin-bottom: 1.6rem;
}

.cardText {
    font-size: $text-base;
    line-height: 1.8rem;
    color: $grey;
    overflow-wrap: break-word;
    opacity: 0.6;
}

.separator {
    width: 100%;
    height: 0.1rem;
    opacity: 0.5;
    background-color: rgba(66, 66, 66, 0.42);
    margin: 2.4rem 0;
}

.cardButton {
    @extend .MikadoBold;
    font-weight: 700;
    font-size: $text-base;
    line-height: 1.8rem;
    @include parentPortalLinkColor;
}

.deleteAccountInfo {
    margin-top: 1.5rem;
    font-size: $text-small;
    line-height: 2rem;
    color: $grey;
    padding: 0 0.1rem;

    a {
        @include link;
    }

    @screen md {
        margin-top: 2.5rem;
    }
}

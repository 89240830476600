@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.modalContent {
    max-width: 50rem;
    padding: 4rem 3.2rem 2.4rem;
    margin: 2rem;
    text-align: center;
}

.text {
    color: #4c4b5d;
    font-size: $text-increased;
    font-weight: 400;
    line-height: 2.6rem;
    letter-spacing: 0.05px;
    margin-top: 0.7rem;
}

.buttonsContainer {
    margin-top: 3.2rem;

    @screen md {
        & > button:first-child {
            margin-right: 2rem;
        }
    }
}

.buttonGrey {
    background-color: #9f9f9f;
}

@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.appWrapper {
    width: 100%;
    display: flex;
}

.pageWrapper {
    width: 100%;
    padding: 9rem 2rem;

    @screen md {
        padding: 5rem 4rem;
    }
}

@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.card {
    max-width: 77.7rem;
    margin-top: 2.2rem;
    padding: 2.4rem 1.6rem;

    @screen md {
        margin-top: 4rem;
        padding: 3.2rem;
    }
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cardTitle {
        font-weight: 500;
        font-size: $text-base;
        line-height: 1.8rem;
        color: $grey;
        margin-right: 1rem;
    }

    .subscriptionStatusChip {
        border-radius: 100px;
        @extend .MikadoMedium;
        font-size: $text-small;
        font-weight: 500;
        line-height: 1.8rem;
        padding: 0.3rem 1.7rem;
        cursor: default;

        &.green {
            background-color: rgba(0, 160, 118, 0.1);
        }

        &.red {
            background-color: rgba(241, 63, 63, 0.2);
        }
    }
}

.cardText {
    font-size: $text-base;
    line-height: 2.4rem;
    color: $grey;
    overflow-wrap: break-word;
    opacity: 0.8;
}

.separator {
    width: 100%;
    height: 0.1rem;
    opacity: 0.5;
    background-color: rgba(66, 66, 66, 0.42);
    margin: 2.4rem 0;
}

.cardButton {
    @extend .MikadoBold;
    font-weight: 700;
    font-size: $text-base;
    line-height: 1.8rem;
    @include parentPortalLinkColor;

    &:first-child {
        margin-right: 2rem;
    }
}

.errorMessage {
    @extend .MikadoBold;
    font-weight: 700;
    color: $bright-red;
    font-size: $text-base;
    line-height: 1;
    letter-spacing: 0.18px;
    margin-top: 2rem;
}

@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.modalContent {
    max-width: 70rem;
    padding: 4rem 3.2rem 2.4rem;
    margin: 2rem;
    text-align: center;
}

.icon {
    height: 6.4rem;
    width: 6.4rem;
    margin: 0 auto 1.6rem;
}

.title {
    font-size: $subtitle-large;
    font-weight: 700;
    line-height: 4rem;
}

.text {
    font-size: $text-large;
    font-weight: 400;
    line-height: 2.8rem;
    margin-top: 1.6rem;
}

.buttonsContainer {
    margin-top: 2.4rem;

    button {
        margin-block: 0;
    }

    & > button:first-child {
        margin-bottom: 1.2rem;
    }

    @screen md {
        margin-top: 3.2rem;

        & > button:first-child {
            margin-right: 2rem;
        }
    }
}

.buttonOutlined {
    background-color: transparent;
    color: $grey;
    border: 1.524px solid rgba(66, 66, 66, 0.15);
}

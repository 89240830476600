@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.root {
    width: 23.3rem;
    min-width: 23.3rem;
    min-height: 100vh;
    background-color: $white;
}

.logoContainer {
    text-align: center;
    padding: 5rem 2rem 7rem;

    .logoIcon {
        display: inline-block;
    }
}

.menu {
    list-style-type: none;
    text-align: left;
}

.menuItemLink {
    display: flex;
    width: 100%;
    padding: 1.7rem 4.3rem;
    border-left: 0.6rem solid transparent;

    & > span {
        font-weight: 400;
        font-size: $text-base;
        line-height: 2.2rem;
        color: $grey;
    }

    &:hover {
        background-color: rgb(251, 251, 251);
    }

    &.active,
    &.active:hover {
        background-color: rgba(239, 104, 35, 0.16);
        border-left: 0.6rem solid $primary;

        & > span {
            font-weight: 700;
        }
    }

    & > svg {
        height: 2rem;
        margin-right: 0.85rem;
    }
}

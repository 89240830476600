@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.formContainer {
    max-width: 64.2rem;
    margin: 2rem auto;
    padding: 2rem 2rem 2.6rem;

    @screen md {
        padding: 3.5rem 11rem;
    }

    .form {
        padding-top: 2rem;
    }

    .forgotPasswordText {
        text-align: center;
        font-weight: 400;
        font-size: $text-base;
        line-height: 1.8rem;
        color: $grey;

        margin-top: 3.2rem;

        a {
            @include link;
            @extend .MikadoBold;
            font-weight: bold;
        }
    }

    .responseErrorsContainer {
        margin: 2rem 0;

        .responseError {
            font-size: $text-base;
            color: $bright-red;
        }
    }
}

.buttonsContainer {
    margin-top: 3rem;

    @screen sm {
        display: flex;
        gap: 1.6rem;
    }
}

.successMessage {
    margin-top: 3rem;
}

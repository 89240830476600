@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 77.7rem;
    margin-top: 2.2rem;
    padding: 2.4rem 1.6rem;

    @screen md {
        margin-top: 4rem;
        padding: 3.2rem;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;

        @screen md {
            flex-direction: row;
            width: 100%;
            gap: 0;
        }

        > button {
            margin: 0 1rem 0;
        }
    }
}

.image {
    width: 10rem;
    height: 10rem;

    @screen md {
        width: 15rem;
        height: 15rem;
    }

    & > img {
        border-radius: 20px;
    }
}

.content {
    .title {
        max-width: 45rem;
        margin: 3rem 0 1rem;
        line-height: normal;

        font-weight: 700;
        font-size: $subtitle;
        text-align: center;

        @screen md {
            font-size: $subtitle-large;
        }
    }

    .text {
        max-width: 45rem;
        margin: 2rem 0;

        line-height: 1.4;

        text-align: center;

        > b {
            font-weight: 700;
        }
    }
}

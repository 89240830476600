@import '@jaramba-frontend/core/styles/variables';

.pageContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @screen lg {
        flex-direction: row;
    }
}

.storeCardsCol {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-basis: 62%;
}

@import '@jaramba-frontend/core/styles/variables';

.pageContent {
    width: 100%;
    padding: 0 2rem 6rem;
}

.title {
    font-weight: 700;
    font-size: $subtitle-large;
    line-height: 4rem;
    text-align: center;
    color: $grey;
    margin: 7rem 0 2rem;
    padding: 0;

    @screen md {
        font-size: $title;
        line-height: 6.4rem;
    }
}

.description {
    max-width: 55.8rem;
    font-weight: 400;
    font-size: $text-base;
    text-align: center;
    color: $grey;
    margin: 0 auto 3.2rem;

    @screen md {
        font-size: $text-increased;
        line-height: 2.8rem;
    }
}

@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.formContainer {
    max-width: 64.2rem;
    margin: 2rem auto;
    padding: 2rem 2rem 2.6rem;

    @screen md {
        padding: 3rem 11rem;
    }

    .forgotPasswordText {
        text-align: center;
        font-weight: 400;
        font-size: $text-base;
        line-height: 1.8rem;
        color: $grey;

        margin-top: 3.2rem;

        a {
            @include link;
            @extend .MikadoBold;
            font-weight: bold;
        }
    }

    .responseErrorsContainer {
        text-align: center;
        margin: 1rem 0 0;

        .responseError {
            font-size: $text-base;
            color: $bright-red;
        }
    }

    .submitButton {
        margin-top: 2.8rem;

        @screen md {
            margin-top: 3rem;
        }
    }
}

@import '@jaramba-frontend/core/styles/variables';

/* JarambaAppCard Styles */
.jarambaAppCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.2rem;
    margin-bottom: 1.6rem;
    text-align: center;

    @screen md {
        flex-direction: row;
        text-align: left;
    }

    .jarambaIcon {
        width: 7.3rem;
        height: 7.3rem;
        margin-right: 0;
        margin-bottom: 1.6rem;

        @screen md {
            margin-right: 2rem;
            margin-bottom: 0;
        }
    }

    .title {
        font-weight: 700;
        font-size: $text-large;
        line-height: 2.7rem;
        color: $grey;
        margin-bottom: 1rem;

        @screen md {
            font-size: $subtitle;
            line-height: 2.8rem;
            margin-bottom: 0.5rem;
        }
    }

    .subtitle {
        font-weight: 400;
        font-size: $text-increased;
        line-height: 2.4rem;
        color: $grey;
        opacity: 0.8;
    }
}

/* AppStoreCard Styles */
.appStoreCard {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2.4rem 3.2rem;

    &:not(:last-child) {
        margin-bottom: 1.6rem;
    }

    .title {
        font-weight: 700;
        font-size: $text-large;
        line-height: 2.7rem;
        color: $grey;
        margin-bottom: 0.8rem;
    }

    .subtitle {
        font-size: $text-base;
        line-height: 1.8rem;
        color: $grey;
        opacity: 0.6;
    }

    .platformIcon {
        width: 3.2rem;
        height: 3.2rem;
        margin-right: 2.4rem;
    }

    .button {
        margin: 0 0 0 auto;
    }
}

.cardInfoCol {
    display: flex;
    flex-direction: column;
}

/* AppQrBannerCard Styles */
.qrCodeCard {
    flex-basis: 38%;
    text-align: center;

    @screen md {
        margin-top: 1.6rem;
    }

    @screen lg {
        margin-top: 0;
        margin-left: 4rem;
    }

    &Banner {
        object-fit: cover;
        border-radius: $paperBorderRadius $paperBorderRadius 0 0;
    }

    &Content {
        padding: 3rem 4.3rem;
    }

    .label {
        font-size: $text-base;
        line-height: 2.8rem;
        letter-spacing: 0.05px;
        color: #4c4b5d;
        margin-bottom: 0.7rem;
    }

    .description {
        font-size: $text-increased;
        line-height: 2.6rem;
        letter-spacing: 0.05px;
        color: #4c4b5d;
    }
}

/* AppQrModal Styles */
.modalContent {
    padding: 4rem 3.2rem 2.4rem;

    .title {
        font-size: $subtitle;
        line-height: 1.8rem;
        color: $grey;
        margin-bottom: 1.8rem;
    }

    .description {
        font-size: $text-increased;
        line-height: 2.6rem;
        letter-spacing: 0.05px;
        color: #4c4b5d;
        margin-bottom: 2.8rem;
    }

    .qrCode {
        width: 16.6rem;
        height: 16.6rem;
        padding: 0.5rem;
        border: 2px solid #4c4b5d;
        border-radius: 5px;
        margin: 0 auto 1.6rem;
    }

    .label {
        font-size: $text-base;
        line-height: 2.8rem;
        text-align: center;
        letter-spacing: 0.05px;
        color: #4c4b5d;
        margin-bottom: 1.6rem;
    }

    .button {
        margin: 0;
    }
}

@import '@jaramba-frontend/core/styles/variables';

.navbar {
    width: 100%;
    height: $headerHeightMobile;
    padding: 0 2rem;

    background-color: $white;

    position: fixed;
    z-index: 99;
    left: 0;
    right: 0;
    box-shadow: $headerBoxShadow;

    @screen md {
        @apply container;

        .jarambaLogo {
            margin-left: 1rem;
        }

        left: 50%;
        transform: translateX(-50%);
        height: 7rem;
        margin-top: 1.6rem;

        border-radius: 100px;
        box-shadow: $headerBoxShadow;
    }
}

.menuItem {
    @extend .MikadoBold;

    font-size: $subtitle;
    line-height: 2.8rem;
    color: $navbar-link-color;
    text-align: center;
    margin: 3.8rem 0;

    .active {
        text-decoration: underline;
    }

    @screen md {
        display: inline-block;
        font-family: MikadoRegular;
        font-size: $text-increased;
        line-height: 2.4rem;
        margin: 0 1rem;
    }
}

.logoutButton {
    display: block;
    padding: 1.4rem 0;

    @screen md {
        display: inline;
        margin: 0;
        color: $grey;
        font-family: MikadoRegular;
        border: 0;
        width: initial;
        margin-right: 3rem;
    }
}

@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.root {
    .title {
        margin-top: 2rem;
        font-size: $text-base;
        opacity: 0.8;
    }

    .productsContainer {
        display: flex;
        flex-direction: column;
        max-width: 86.2rem;
        margin-top: 2rem;

        @screen md {
            flex-direction: row;
        }

        & > div:first-child {
            margin-bottom: 1.6rem;

            @screen md {
                margin-right: 1.6rem;
                margin-bottom: 0;
            }
        }

        & > div {
            width: 100%;
        }
    }
}

@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.root {
    position: relative;
}

.accountDropdownButton {
    display: flex;
    align-items: center;
    cursor: pointer;

    & > .email {
        max-width: 18rem;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: $text-base;
        line-height: 1.9rem;

        color: $grey;

        @screen lg {
            max-width: 30rem;
        }
    }
}

.avatar {
    height: 2.6rem;
    width: 2.6rem;
    background-color: $primary;
    border-radius: 100%;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    font-size: $text-base;
    line-height: 1.7;
    cursor: pointer;
    user-select: none;
    margin-right: 0.8rem;
}

.triangleIcon {
    width: 0;
    height: 0;
    border-left: 0.45rem solid transparent;
    border-right: 0.45rem solid transparent;
    border-top: 0.7rem solid $grey;
    margin-left: 1.4rem;
    transition: transform 0.1s;

    &.reversed {
        transform: rotate(-180deg);
    }
}

.dropdownMenu {
    max-width: 40rem;
    background-color: $white;
    border-radius: $paperBorderRadius;
    padding: 0 1.5rem;
    box-shadow: $paperBoxShadow;
    position: absolute;
    top: 4.5rem;
    right: 0;
    z-index: 10;
}

.menuItem {
    padding: 1rem;
    border-radius: $paperBorderRadius;
    position: relative;
    margin: 0.7rem 0;
    overflow-wrap: break-word;

    &:not(:last-child) {
        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            content: '';
            width: 100%;
            height: 0.1rem;
            background-color: rgba(66, 66, 66, 0.08);
        }
    }
}

.button {
    width: 100%;
    cursor: pointer;
    font-weight: 700;
    text-align: left;
    padding: 1rem;
    border-radius: $paperBorderRadius;
    margin: 0 0 0.7rem;
    overflow-wrap: break-word;
    @include parentPortalLinkColor;

    &:hover {
        background-color: rgb(251, 251, 251);
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@jaramba-frontend/core/styles/global.scss';

.toastErrorMessage,
.toastSuccessMessage {
    font-weight: 500;
    font-family: MikadoMedium, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.toastErrorMessage {
    background-color: $bright-red !important;
}

.toastSuccessMessage {
    background-color: $bright-green !important;
}

.toastWarningMessage {
    background-color: $primary !important;
}

@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.subscriptionStatusChip {
    border-radius: 100px;
    @extend .MikadoMedium;
    font-size: $text-small;
    font-weight: 500;
    line-height: 1.8rem;
    padding: 0.3rem 1.2rem;
    cursor: default;
    text-align: center;

    @screen md {
        padding: 0.3rem 1.7rem;
    }

    &.green {
        background-color: rgba(0, 160, 118, 0.1);
    }

    &.red {
        color: #f13f3f;
        background-color: rgba(241, 63, 63, 0.2);
    }
}

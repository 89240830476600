@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.root {
    display: flex;
    justify-content: space-between;
}

.title {
    font-weight: 700;
    font-size: $subtitle;
    line-height: 3.2rem;
    color: $grey;

    @screen md {
        font-size: $subtitle-large;
    }
}
